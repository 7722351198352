import React from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  BooleanInput,
  TextInput,
  BooleanField,
} from "react-admin";

const TodoFilter = (props) => (
  <Filter {...props}>
    <TextInput
      alwaysOn
      label="Search by Name"
      source="name"
      variant="outlined"
    />
    <BooleanInput label="Status" source="status" defaultValue={true} />
  </Filter>
);

const PoolList = (props) => (
  <List filters={<TodoFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="website" />
      <TextField source="api" />
      <TextField source="email" />
      <TextField source="fees" />
      <TextField source="min_pay" />
      <BooleanField source="status" label="Enabled" />
    </Datagrid>
  </List>
);

export default PoolList;
