import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  BooleanInput,
  TextInput,
  BooleanField,
  SelectInput,
  ImageField,
} from "react-admin";

import TypeField, { typeOptions } from "./Type";

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 96,
    maxHeight: 96,
  },
}));

const TodoFilter = (props) => (
  <Filter {...props}>
    <TextInput
      alwaysOn
      label="Search by Title"
      source="title"
      variant="outlined"
    />
    <SelectInput
      alwaysOn
      source="type"
      variant="outlined"
      choices={typeOptions}
    />
    <BooleanInput
      allowEmpty
      label="Status"
      source="status"
      defaultValue={true}
    />
  </Filter>
);

const AdsList = (props) => {
  const classes = useStyles();

  return (
    <List filters={<TodoFilter />} bulkActionButtons={false} {...props}>
      <Datagrid rowClick="edit">
        <ImageField source="image" title="title" classes={classes} />
        <TextField source="title" />
        <TextField source="link" />
        <TextField source="text" />
        <TypeField />
        <BooleanField source="status" label="Enabled" />
      </Datagrid>
    </List>
  );
};

export default AdsList;
