import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  SelectInput,
} from "react-admin";

import { tagOptions } from "./Tag";

const LabelAdd = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="address" validate={[required()]} variant="outlined" />
      <TextInput source="label" validate={[required()]} variant="outlined" />
      <TextInput source="tag" validate={[required()]} variant="outlined" />
      <SelectInput
        source="tag"
        validate={[required()]}
        choices={tagOptions}
        variant="outlined"
      />
      <TextInput source="website" variant="outlined" />
      <TextInput source="twitter" variant="outlined" />
      <TextInput source="blog" variant="outlined" />
      <TextInput source="telegram" variant="outlined" />
      <TextInput source="discord" variant="outlined" />
      <TextInput source="keybase" variant="outlined" />
      <BooleanInput source="status" label="Status" variant="outlined" />
    </SimpleForm>
  </Create>
);

export default LabelAdd;
