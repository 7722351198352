import { useState, useEffect } from "react";
import buildHasuraProvider from "ra-data-hasura";

import client from "./client";
import authProvider from "./auth";

const useProvider = () => {
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    const buildDataProvider = async () => {
      const dataProvider = await buildHasuraProvider({ client });
      setDataProvider(() => dataProvider);
    };

    buildDataProvider();
  }, []);

  return [dataProvider, authProvider];
};

export default useProvider;
