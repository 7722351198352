import React from "react";
import {
  Create,
  BooleanInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

const PoolAdd = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} variant="outlined" />
      <TextInput source="website" validate={[required()]} variant="outlined" />
      <TextInput source="api" variant="outlined" />
      <TextInput source="email" variant="outlined" />
      <TextInput source="fees" variant="outlined" />
      <TextInput source="min_pay" variant="outlined" />
      <BooleanInput source="status" label="Status" variant="outlined" />
    </SimpleForm>
  </Create>
);

export default PoolAdd;
