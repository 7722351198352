import React from "react";
import { Admin, Resource } from "react-admin";

import Ads from "./ads";
import Login from "./Login";
import Pools from "./pools";
import Labels from "./labels";
import useProvider from "./providers";

const App = () => {
  const [dataProvider, authProvider] = useProvider();

  if (!dataProvider) return <p>Loading...</p>;

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={Login}
    >
      <Resource
        name="pools"
        icon={Pools.Icon}
        list={Pools.List}
        create={Pools.Add}
        edit={Pools.Edit}
      />
      <Resource
        name="banners"
        options={{ label: "Ads" }}
        icon={Ads.Icon}
        list={Ads.List}
        create={Ads.Add}
        edit={Ads.Edit}
      />
      <Resource
        name="addresses"
        options={{ label: "Labels" }}
        icon={Labels.Icon}
        list={Labels.List}
        create={Labels.Add}
        edit={Labels.Edit}
      />
    </Admin>
  );
};

export default App;
