import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useLogin, useNotify, Notification, Login } from "react-admin";

const LoginForm = (props) => {
  const login = useLogin();
  const notify = useNotify();
  const [password, setPassword] = useState("");

  const submit = (e) => {
    e.preventDefault();

    login({ password }).catch(() => notify("Invalid email or password"));
  };

  return (
    <Login {...props}>
      <form style={{ padding: 16 }} onSubmit={submit}>
        <TextField
          required
          fullWidth
          size="small"
          variant="outlined"
          name="password"
          type="password"
          label="Admin secret"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          fullWidth
          disableElevation
          type="submit"
          variant="contained"
          color="primary"
          style={{ margin: "16px 0 8px" }}
        >
          ENTER
        </Button>
      </form>
      <Notification />
    </Login>
  );
};

export default LoginForm;
