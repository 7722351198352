import React from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  TextInput,
  BooleanField,
  SelectField,
  SelectInput,
} from "react-admin";

import TagField, { tagOptions } from "./Tag";

const LabelFilter = (props) => (
  <Filter {...props}>
    <TextInput
      alwaysOn
      label="Search by Label"
      source="label"
      variant="outlined"
    />
    <TextInput
      alwaysOn
      label="Search by Address"
      source="address"
      variant="outlined"
    />
    <SelectInput
      alwaysOn
      source="tag"
      choices={tagOptions}
      variant="outlined"
    />
  </Filter>
);

const LabelList = (props) => {
  return (
    <List filters={<LabelFilter />} bulkActionButtons={false} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="label" />
        <TextField source="address" />
        <TagField />
        <BooleanField source="status" label="Enabled" />
      </Datagrid>
    </List>
  );
};

export default LabelList;
