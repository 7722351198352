import React from "react";
import {
  Edit,
  BooleanInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  required,
  SelectInput,
} from "react-admin";

import { typeOptions } from "./Type";

const AdsTitle = ({ record }) => {
  return <span>Ads: {record ? `${record.name}` : ""}</span>;
};

const EditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label="Back" />
  </TopToolbar>
);

const AdsEdit = (props) => (
  <Edit title={<AdsTitle />} actions={<EditActions />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" label="Ads Id" variant="outlined" />
      <TextInput source="title" validate={[required()]} variant="outlined" />
      <TextInput source="link" validate={[required()]} variant="outlined" />
      <TextInput source="text" variant="outlined" />
      <TextInput source="image" variant="outlined" />
      <SelectInput
        source="type"
        variant="outlined"
        choices={typeOptions}
      />
      <BooleanInput source="status" label="Status" variant="outlined" />
    </SimpleForm>
  </Edit>
);

export default AdsEdit;
