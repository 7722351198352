import React from "react";
import { Chip } from "@material-ui/core";
import lowerCase from "lodash/lowerCase";
import capitalize from "lodash/capitalize";

export const tagOptions = [
  { id: "service", name: "Service" },
  { id: "pool", name: "Pool" },
  { id: "personal", name: "Personal" },
  { id: "shop", name: "Shop" },
  { id: "donation", name: "Donation" },
  { id: "centralised-exchange", name: "Centralised Exchange" },
  { id: "decentralised-exchange", name: "Decentralised Exchange" },
];

const Tag = ({ record }) => {
  if (record && record.tag) {
    return <Chip label={capitalize(lowerCase(record.tag))} />;
  }

  return null;
};

Tag.defaultProps = { label: "Tag", source: "tag" };

export default Tag;
