import React from "react";
import {
  Create,
  BooleanInput,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
} from "react-admin";

import { typeOptions } from "./Type";

const AdsAdd = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" validate={[required()]} variant="outlined" />
      <TextInput source="link" validate={[required()]} variant="outlined" />
      <TextInput source="text" variant="outlined" />
      <TextInput source="image" variant="outlined" />
      <SelectInput
        source="type"
        variant="outlined"
        choices={typeOptions}
      />
      <BooleanInput source="status" label="Status" variant="outlined" />
    </SimpleForm>
  </Create>
);

export default AdsAdd;
