import React from "react";
import {
  Edit,
  BooleanInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  required,
} from "react-admin";

const PoolTitle = ({ record }) => {
  return <span>Pool: {record ? `${record.name}` : ""}</span>;
};

const EditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label="Back" />
  </TopToolbar>
);

const PoolEdit = (props) => (
  <Edit title={<PoolTitle />} actions={<EditActions />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" label="Pool Id" variant="outlined" />
      <TextInput source="name" validate={[required()]} variant="outlined" />
      <TextInput source="website" validate={[required()]} variant="outlined" />
      <TextInput source="api" variant="outlined" />
      <TextInput source="email" variant="outlined" />
      <TextInput source="fees" variant="outlined" />
      <TextInput source="min_pay" variant="outlined" />
      <BooleanInput source="status" label="Status" />
    </SimpleForm>
  </Edit>
);

export default PoolEdit;
