import React from "react";

const getType = (key) => {
  // eslint-disable-next-line default-case
  switch (key) {
    case "banner1":
      return "Banner Ad 1";
    case "banner2":
      return "Banner Ad 2";
    case "text":
      return "Text Ad";
    case "buy":
      return "Buy Box";
    case "exchange":
      return "Exchange Box";
    case "earn":
      return "Earn Box";
  }
};

export const typeOptions = [
  { id: "banner1", name: getType("banner1") },
  { id: "banner2", name: getType("banner2") },
  { id: "text", name: getType("text") },
  { id: "buy", name: getType("buy") },
  { id: "exchange", name: getType("exchange") },
  { id: "earn", name: getType("earn") },
];

const Type = ({ record }) => {
  if (record) {
    return <div>{getType(record.type)}</div>;
  }

  return null;
};

Type.defaultProps = { label: "Type", source: "type" };

export default Type;
