const authProvider = {
  login: ({ password }) => {
    localStorage.setItem("auth", password);

    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem("auth");

    return Promise.resolve();
  },
  getIdentity: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
